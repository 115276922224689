var __jsx = React.createElement;
/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';
import { getTrackingDetails } from 'utils/tracking';
var TagManager = function TagManager(_ref) {
  var environment = _ref.environment,
    cookiesAgreed = _ref.cookiesAgreed;
  var _getTrackingDetails = getTrackingDetails(environment),
    enabled = _getTrackingDetails.enabled,
    containerId = _getTrackingDetails.containerId;

  // If tracking is not enabled or cookies are not agreed to, don't add any tracking scripts.
  if (!enabled || !cookiesAgreed) return null;
  var matomoName = 'biomage';

  // Matomo tracking script
  var mtmTrackingCode = "var _mtm = window._mtm = window._mtm || [];\n            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});\n            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n            g.async=true; g.src='https://cdn.matomo.cloud/".concat(matomoName, ".matomo.cloud/container_").concat(containerId, ".js'; s.parentNode.insertBefore(g,s);");

  // Google Tag Manager script
  var gtagTrackingCode = "\n    window.dataLayer = window.dataLayer || [];\n    function gtag(){dataLayer.push(arguments);}\n    gtag('js', new Date());\n    gtag('config', 'G-HCJZZEB9WT');\n  ";
  return __jsx(Head, null, __jsx("script", {
    async: true,
    src: "https://www.googletagmanager.com/gtag/js?id=G-HCJZZEB9WT"
  }), __jsx("script", {
    key: "gtag",
    dangerouslySetInnerHTML: {
      __html: gtagTrackingCode
    }
  }), __jsx("script", {
    key: "mtm",
    dangerouslySetInnerHTML: {
      __html: mtmTrackingCode
    }
  }));
};
export default TagManager;